// The file contents for the current environment for staging purpose

export const environment = {
  name: "demo",
  backendHost: "https://kpi-demo-app.azurewebsites.net",
  capexIntelligenceHost: "https://demo.capex-intelligence.com",
  logo: "logo_kpi_no_bg.png",
  production: true,
  test: false,
  hasGoogleLogin: false,
  hasPasswordLogin: true,
  hasMicrosoftLogin: true,
  sentryDSN: "https://a2627fcf3f344a73b1c12e1b7cb5eb63@sentry.io/1447058",
  showDocDefaultsInInvestmentWizard: false,
  supportEmail: null,
  supportPhone: null,
  roadmapConfiguration: 2,
};
